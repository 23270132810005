/** @format */

import { TLocale } from './gatsby/types';

export const BUILD_CONFIG = {
  buildItaly: true,
  buildFrance: true,
  buildSpain: true,
  buildCountries: function () {
    const countries = [];
    if (this.buildItaly) countries.push('it');
    if (this.buildFrance) countries.push('fr');
    if (this.buildSpain) countries.push('es');
    return countries;
  },
  buildLocaleIT: true,
  buildLocaleEN: true,
  buildLocaleFR: process.env.GATSBY_ENV !== 'production',
  buildLocales: function (): TLocale[] {
    const locales: TLocale[] = [];
    if (this.buildLocaleEN) locales.push('en');
    if (this.buildLocaleIT) locales.push('it');
    if (this.buildLocaleFR) locales.push('fr');
    return locales;
  },
  buildPOI: true,
  buildSearch: true,
  buildCities: true,
  buildPDP: true,
  buildStatic: true,
  buildSitemap: true,
  debug: false,
  buildPartitions: function () {
    const partitions = [];
    const countries = this.buildCountries();
    const isITorES = countries.includes('it') && countries.includes('es');
    if (isITorES) {
      partitions.push([0, 1, 2], [3, 4, 5], [6, 7, 8]);
      if (countries.includes('fr')) partitions.push([9, 10]);
      else partitions.push([9]);
    } else if (countries.includes('fr')) partitions.push([10]);

    return partitions;
  },
};
